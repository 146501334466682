import React from "react"
import { useTranslation } from "react-i18next"
import Seaconnectlogo from "../../assets/seaconnectlogo.svg"

const Aside = () => {
  const { t } = useTranslation()
  return (
    <div className="hidden lg:flex flex-col justify-center items-start lg:w-1/2 px-10 h-full">
      <h1 className="text-azul font-bold text-4xl border-b border-azul pb-5 mb-5 block w-full">
        {t("contacto.presentacion")}
      </h1>
      <div className="flex flex-row items-center justify-center divide-x-2 border-azul space-x-5">
        <Seaconnectlogo alt="Sea Connect Logo" className="w-60 mt-7 mb-4" />
        <ul
          dangerouslySetInnerHTML={{ __html: t("contacto.direccion") }}
          className="text-base  text-azul-dark pl-5"
        />
      </div>

      {/* GOOGLE MAPS  */}
      <div className="w-full mt-10">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3075.8942081110913!2d2.6723713160749134!3d39.56199291509866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x129793bedee9762b%3A0x9242c729a5e56d09!2sCarrer%20Ciutat%20de%20la%20Plata%2C%205%2C%2007006%20Palma%2C%20Illes%20Balears!5e0!3m2!1ses!2ses!4v1622050588914!5m2!1ses!2ses"
          width="600"
          height="550"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          className="w-full h-96"
          title="localizacion"
        ></iframe>
      </div>
    </div>
  )
}

export default Aside
