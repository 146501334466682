import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Hero = props => {
  const { heroImg, heroImgSmart } = props

  return (
    <section className="flex flex-col items-center justify-center mt-0 xl:mt-28">
      <div className="hidden md:block w-full mx-auto">
        <GatsbyImage image={heroImg} alt="hero Image" className="w-full" />
      </div>
      <div className="md:hidden">
        <GatsbyImage image={heroImgSmart} alt="hero Image" />
      </div>
    </section>
  )
}

export default Hero
