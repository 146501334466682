import React from "react"
import Nav from "./nav/Nav"
import FooterContacto from "./footer/FooterContacto"
import Cookies from "./Cookies"
import Seo from "./Seo"

const LayoutContacto = ({ children, datos }) => {
  return (
    <>
      <Seo datos={datos} />
      <Nav />
      <main className="cursor-default">{children}</main>
      <Cookies />
      <FooterContacto />
    </>
  )
}

export default LayoutContacto
