import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import LayoutContacto from "../components/LayoutContacto"
import Hero from "../components/contacto/Hero"
import Aside from "../components/contacto/Aside"
import Formulario from "../components/contacto/Formulario"

const Contacto = ({ data }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const heroImg = getImage(data.heroImg)
  const heroImgSmart = getImage(data.heroImgSmart)

  const seo = {
    title: t("contacto.seo.title"),
    description: t("contacto.seo.description"),
    lang: language,
  }

  return (
    <LayoutContacto datos={seo}>
      <Hero heroImg={heroImg} heroImgSmart={heroImgSmart} />

      <div className="flex flex-col items-center justify-center lg:flex-row lg:items-stretch container p-5 md:p-0 my-10">
        <Aside />
        <Formulario />
      </div>
    </LayoutContacto>
  )
}

export default Contacto

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    heroImg: file(relativePath: { eq: "heroContact.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
    }
    heroImgSmart: file(relativePath: { eq: "heroContactSmart.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
    }
  }
`
